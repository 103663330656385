<template>
  <div>
    <template v-if="isAllCompleted">
      <div class="card mb-4 mt-4">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h6 class="mb-0">
            <fa-icon :icon="['fa', 'money-check-alt']" class="mr-3 text-secondary" />
            Informasi Pembiayaan
          </h6>
          <!-- <button @click="action" class="btn btn-sm btn-primary"><icon :icon="['fa', 'save']" class="mr-2"/>Simpan</button> -->
        </div>
        <div class="card-body">
          <div>
            <span>Anda telah memilih rencana pendidikan :</span>
            <div v-if="biodatas.pesertadidik.pendidikan">
              <div class="row">
                <div class="col" style="max-width: 100px">Lembaga</div>
                <div class="col font-weight-bold">
                  :
                  {{
                    biodatas.pesertadidik.pendidikan
                      ? biodatas.pesertadidik.pendidikan.lembaga
                      : "-"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col" style="max-width: 100px">Jurusan</div>
                <div class="col font-weight-bold">
                  :
                  {{
                    biodatas.pesertadidik.pendidikan
                      ? biodatas.pesertadidik.pendidikan.jurusan
                      : "-"
                  }}
                </div>
              </div>
            </div>
            <div v-else class="font-weight-bold">Mondok saja (Khorijin).</div>
            <br />
            <template v-if="biodatas.pembayaran.diskon_pangkal === 'Y'">
              <span class="font-italic">
                Karena merupakan alumni dari salah satu satuan pendidikan di
                Nurul Jadid,<br />
                yaitu alumni
                <b class="text-secondary">{{
                  biodatas.pesertadidik.alumni_pendidikan.lembaga
                  }}</b>, maka mendapatkan bebas uang pangkal
              </span>
              <hr />
            </template>

            <div class="row font-weight-light">
              <div class="col pr-2" style="max-width: 20px">&#8226;</div>
              <div class="col pl-1" style="max-width: 200px">
                Biaya Pendaftaran
              </div>
              <div class="col">
                Rp.
                {{ biodatas.pembayaran.rincian.pendaftaran | formattedRupiah }}
              </div>
            </div>
            <div class="row font-weight-light">
              <div class="col pr-2" style="max-width: 20px">&#8226;</div>
              <div class="col pl-1" style="max-width: 200px">
                Biaya BPS (3 Bln)
              </div>
              <div class="col">
                Rp. {{ biodatas.pembayaran.rincian.bps | formattedRupiah }}
              </div>
            </div>
            <div class="row font-weight-light">
              <div class="col pr-2" style="max-width: 20px">&#8226;</div>
              <div class="col pl-1" style="max-width: 200px">Uang Pangkal</div>
              <div class="col">
                Rp.
                {{
                  (biodatas.pembayaran.diskon_pangkal === "Y"
                    ? 0
                    : biodatas.pembayaran.rincian.pangkal) | formattedRupiah
                }}
              </div>
            </div>
            <div class="row font-weight-light" v-if="biodatas.pembayaran.rincian.potongan_alumni_jenjang23">
              <div class="col pr-2" style="max-width: 20px">&#8226;</div>
              <div class="col pl-1" style="max-width: 200px">
                Potongan Alumni untuk Jenjang SLTP/SLTA
              </div>
              <div class="col">
                Rp.
                {{
                  biodatas.pembayaran.rincian.potongan_alumni_jenjang23
                  | formattedRupiah
                }}
              </div>
            </div>
            <div class="mt-2">
              <span class="py-1 font-weight-bold border-top">
                Total biaya yang harus dibayar Rp.
                {{ pembayaran.biaya_pendaftaran | formattedRupiah }}
              </span>
            </div>
          </div>

          <!-- dimunculin ketika VA sudah diaktivasi ke bank -->
          
        <div v-if="isActivatedVA">
          <hr />
          <p class="mt-2">Pembayaran bisa melalui 3 bank dengan nomor virtual akun Anda berikut:
          </p>
          <ul>
            <li>
              <div class="row">
                <div class="col" style="max-width: 100px">BRI</div>
                <div class="col font-weight-bold">: 68752{{ biodatas.akun_pendaftar.no_registrasi }}</div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col" style="max-width: 100px">BNI</div>
                <div class="col font-weight-bold">: 87960{{ biodatas.akun_pendaftar.no_registrasi }}</div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col" style="max-width: 100px">Bank Jatim</div>
                <div class="col font-weight-bold">: 15006{{ biodatas.akun_pendaftar.no_registrasi }}</div>
              </div>
            </li>
          </ul>
          <br />
          <!-- <p>Jika telah selesai melakukan pembayaran, silahkan kirim konfirmasi pembayaran melalui form dibawah ini.</p>

          <hr />

          <div class="form-group row mb-0 pt-2">
            <label for="status" class="col-sm-3 col-form-label">Status Pembayaran</label>
            <div class="col-sm-9">
              <div class="form-row align-items-center">
                <div class="col-md-6">
                  <input type="text" disabled name="status" v-model="pembayaran.status" id="status"
                    class="form-control-plaintext form-control-sm" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0 pt-2">
            <label for="jumlah_pembayaran" class="col-sm-3 col-form-label">Jumlah Pembayaran</label>
            <div class="col-sm-9">
              <div class="form-row align-items-center">
                <div class="col-md-6">
                  <input type="number" name="Jumlah Pembayaran" v-validate="'required|numeric'"
                    :class="{ 'is-invalid': errors.has('Jumlah Pembayaran') }" v-model="pembayaran.jumlah_pembayaran"
                    id="jumlah_pembayaran" class="form-control form-control-sm" :disabled="disabledForm" />
                  <div v-show="errors.has('Jumlah Pembayaran')" class="invalid-feedback"
                    v-text="errors.first('Jumlah Pembayaran')"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0 pt-2">
            <label for="bank" class="col-sm-3 col-form-label">Bank</label>
            <div class="col-sm-9">
              <div class="form-row align-items-center">
                <div class="col-md-6">
                  <div v-for="(field, i) in opsi_bank" :key="i" class="form-check form-check-inline">
                    <input name="Bank" v-validate="'required|included:' + opsi_bank.join(',')" v-model="pembayaran.bank"
                      class="form-check-input" type="radio" :id="field" :value="field" :disabled="disabledForm">
                    <label class="form-check-label" :for="field" v-text="field"></label>
                  </div>
                  <div v-show="errors.has('Bank')" class="invalid-feedback" style="display: block;"
                    v-text="errors.first('Bank')"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0 pt-2">
            <div class="col-sm-3"></div>
            <div class="col-sm-9">
              <button v-if="!disabledForm" @click="action" class="btn btn-sm btn-primary"><fa-icon
                  :icon="['fa', 'save']" class="mr-2" />Konfirmasi Pembayaran</button>
            </div>
          </div>

          <div class="text-muted mt-4">
            <small>
              <ol>
                <li>Konfirmasi Pembayaran ini hanya bisa dilakukan sekali.</li>
                <li>Ketika terjadi kesalahan silahkan segera hubungi bagian Keuangan.</li>
                <li>Kontak person +62 812-2753-3330 (Bag. Keuangan)</li>
              </ol>
            </small>
          </div> -->

        </div>
        <div v-else class="alert alert-warning mt-4" role="alert">
          VA sedang proses aktivasi, silahkan cek halaman ini secara berkala. <br />
          Silahkan kontak bagian keuangan apabila kesulitan dalam membayar pendaftaran baik secara mobile banking, ATM,
          atau melalui bank terdekat di sekitar Anda.
        </div>

        </div>
      </div>

      <!-- <BankPayment :pendaftar="biodatas" @refreshBio="$emit('refreshBio', true)"></BankPayment> -->
    </template>

    <!-- Ketika data tidak complete -->
    <div v-else>
      <div class="alert alert-danger mt-3" role="alert">
        <strong>Silahkan lengkapi terlebih dahulu data berikut:</strong>
        <ul style="list-style-type: disc; padding-left: 1rem">
          <li v-if="!isFotodiriCompleted">Foto diri</li>
          <li v-if="!isKeluargaCompleted">Data orang tua / wali</li>
          <li v-if="isKeluargaCompleted && !isIbuKandungCompleted">
            Ibu Kandung harus diisi
          </li>
          <li v-if="!isBerkasCompleted">
            Scan berkas Asli (bukan fotokopi)
            <ul style="list-style-type: '- '; padding-left: 1rem">
              <li v-if="!hasBerkasAkta">Scan akte kelahiran peserta didik</li>
              <li v-if="!hasBerkasKK">Scan kartu keluarga (KK)</li>
              <li v-if="!hasBerkasKTP">Scan KTP ortu / wali</li>
              <li>
                Scan ijazah terakhir / SHUN
                <span class="font-italic">(opsional jika sudah ada)</span>
              </li>
            </ul>
          </li>
          <li v-if="!isMutasiBerkasCompleted">
            Karena jenis daftar MUTASI, lengkapi juga dengan berkas<br />
            <ul style="list-style-type: '- '; padding-left: 1rem">
              <li>Surat Keterangan Menerima dari Sekolah Tujuan</li>
            </ul>
          </li>
        </ul>
      </div>
      <div>
        <div class="alert alert-warning" role="alert">
          Pembayaran ini bisa dilakukan setelah melengkapi data &amp; virtual
          akun Bank telah diaktifkan.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BankPayment from "../../components/BankPayment";
export default {
  components: { BankPayment },
  props: ["biodatas"],
  data() {
    return {
      opsi_bank: ["BRI", "BNI", "BANK JATIM"],
      pembayaran: {
        jumlah_pembayaran: null,
        status: null,
        bank: null,
        biaya_pendaftaran: null,
      },
      hasBerkasKK: false,
      hasBerkasKTP: false,
      hasBerkasAkta: false,
      hasBerkasKeteranganMenerima: false,
    };
  },
  methods: {
    init() {
      if (this.biodatas.pembayaran) {
        this.pembayaran = {
          status:
            this.biodatas.pembayaran.status.substr(0, 1).toUpperCase() +
            this.biodatas.pembayaran.status.substr(1),
          biaya_pendaftaran: this.biodatas.pembayaran.biaya_pendaftaran,
          bank: this.biodatas.pembayaran.bank,
          jumlah_pembayaran: this.biodatas.pembayaran.jumlah_pembayaran,
        };
      } else {
        this.pembayaran = {
          jumlah_pembayaran: null,
          status: null,
          bank: null,
          biaya_pendaftaran: null,
        };
      }

      if (Array.isArray(this.biodatas.berkas)) {
        for (let i = 0, len = this.biodatas.berkas.length; i < len; i++) {
          const idKatImg = this.biodatas.berkas[i].id_kategori_image;
          if (idKatImg === 1) {
            this.hasBerkasKK = true;
          } else if (idKatImg === 2) {
            this.hasBerkasKTP = true;
          } else if (idKatImg === 3) {
            this.hasBerkasAkta = true;
          } else if (idKatImg === 22) {
            this.hasBerkasKeteranganMenerima = true;
          }
        }
      }

      this.$validator.reset();
    },
    // action() {
    //   this.$validator.validateAll().then(result => {
    //     if (result) {
    //       this.$axios.put('/pendaftar/' + this.biodatas.uuid + '/konfirmasi_pembayaran', {
    //         bank: this.pembayaran.bank,
    //         jumlah_pembayaran: this.pembayaran.jumlah_pembayaran
    //       })
    //         .then(res => {
    //           this.$emit('refreshBio', true);
    //           this.$root.$emit('setNotif', {
    //             title: res.data.status,
    //             message: res.data.deskripsi,
    //             type: 'success'
    //           });
    //         }).catch(() => { });
    //     }
    //   });
    // }
  },
  filters: {
    formattedRupiah(val) {
      return new Intl.NumberFormat("id-ID", { useGrouping: true }).format(val);
    },
  },
  computed: {
    disabledForm() {
      return this.biodatas.pembayaran.jumlah_pembayaran !== null;
    },
    isActivatedVA() {
      return this.biodatas.pesertadidik.is_activated_va === "Y";
    },
    isAllCompleted() {
      return (
        this.isFotodiriCompleted &&
        this.isKeluargaCompleted &&
        this.isIbuKandungCompleted &&
        this.isBerkasCompleted &&
        this.isMutasiBerkasCompleted
      );
    },
    isFotodiriCompleted() {
      return (
        this.biodatas.fotodiri &&
        this.biodatas.fotodiri.normal &&
        this.biodatas.fotodiri.normal !== "/img/default/default_no_face_n.jpg"
      );
    },
    isKeluargaCompleted() {
      return this.biodatas.keluarga && this.biodatas.keluarga.length > 0;
    },
    isIbuKandungCompleted() {
      // ini diperlukan karena pembuatan rekening bank butuh nama ibu kandung
      let completed = false;
      if (this.isKeluargaCompleted) {
        for (let i = 0, len = this.biodatas.keluarga.length; i < len; i++) {
          let relasiKeluarga = this.biodatas.keluarga[i].relasi_keluarga;
          if (relasiKeluarga.kode_relasi === "12Y") {
            completed = true;
          }
        }
      }
      return completed;
    },
    isBerkasCompleted() {
      return this.hasBerkasKK && this.hasBerkasKTP && this.hasBerkasAkta;
    },
    isMutasiBerkasCompleted() {
      const isMutasi =
        this.biodatas.pesertadidik &&
        this.biodatas.pesertadidik.jenis_daftar === "MUTASI";

      if (!isMutasi) {
        return true;
      }

      return isMutasi && this.hasBerkasKeteranganMenerima;
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    biodatas: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
};
</script>
