<template>
  <div>
    <div class="card mb-4 mt-4">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h6 class="mb-0">
          <fa-icon :icon="['fa', 'money-check-alt']" class="mr-3 text-secondary" />
          Pembayaran
        </h6>
      </div>

      <div class="card-body">
        <div>
          <div class="row py-1">
            <div class="col" style="max-width: 200px">Status Pembayaran</div>
            <div class="col font-weight-bold">
              {{ hasBiaya ? pendaftar.pembayaran?.status : 'Gratis' }}
            </div>
          </div>
          <div class="row py-1" v-if="pendaftar.pembayaran?.jumlah_pembayaran">
            <div class="col" style="max-width: 200px">
              Total Pembayaran Diterima
            </div>
            <div class="col">
              Rp. {{ pendaftar.pembayaran.jumlah_pembayaran | formattedRupiah }}
            </div>
          </div>
        </div>

        <div v-if="hasInvoices" class="mt-4">
          <hr class="my-2" />
          <div class="h6 text-secondary">Tagihan Virtual Account Aktif</div>
          <hr class="my-2" />
          <div v-for="(item, index) in pendaftar.tagihan" :key="index" class="mb-2">
            <div class="row py-1">
              <div class="col" style="max-width: 200px">Bank</div>
              <div class="col font-weight-bold">{{ item.bank }}</div>
            </div>
            <div class="row py-1">
              <div class="col" style="max-width: 200px">
                No. Virtual Account (VA)
              </div>
              <div class="col font-weight-bold">{{ item.va_number }}</div>
            </div>
            <div class="row py-1">
              <div class="col" style="max-width: 200px">Total tagihan</div>
              <div class="col">Rp. {{ item.amount | formattedRupiah }}</div>
            </div>
            <div class="row py-1">
              <div class="col" style="max-width: 200px">Batas waktu hingga</div>
              <div class="col">
                {{ formatFromIso(item.expired) }}
              </div>
            </div>
          </div>

          <div class="text-muted mt-5 ml-2">
            <small>
              <ol>
                <li>
                  Segera melunasi tagihan sebelum batas waktu berlaku Vritual
                  Account (VA).
                </li>
                <li>
                  Ketika terjadi kesalahan tagihan, silahkan segera hubungi
                  bagian Keuangan.
                </li>
              </ol>
            </small>
          </div>
        </div>

        <div v-else-if="notLunas && hasBiaya" class="mt-4">
          <hr class="my-2" />
          <div class="h6 text-secondary">Pilih Metode Pembayaran Virtual Account (VA)</div>
          <hr class="my-2" />
          <div class="form-group py-2">
            <div class="form-check form-check-inline">
              <input name="jenis kelamin" v-model="bank" class="form-check-input" type="radio" id="jatim"
                value="jatim" />
              <label class="form-check-label" for="jatim">
                Bank Jatim
              </label>
            </div>
          </div>
          <div class="form-group py-2">
            <div class="form-check form-check-inline">
              <input name="jenis kelamin" v-model="bank" class="form-check-input" type="radio" id="bri" value="bri"
                disabled />
              <label class="form-check-label" for="bri">
                Bank BRI <small class="font-italic">(maintenance)</small>
              </label>
            </div>
          </div>
          <div class="form-group py-2">
            <div class="form-check form-check-inline">
              <input name="jenis kelamin" v-model="bank" class="form-check-input" type="radio" id="bni" value="bni"
                disabled />
              <label class="form-check-label" for="bni">
                Bank BNI <small class="font-italic">(maintenance)</small>
              </label>
            </div>
          </div>

          <div class="mt-2">
            <button type="submit" class="btn btn-primary" :disabled="!bank" @click="bayar">
              <fa-icon :icon="['fa', 'money-bill-alt']" class="mr-1" /> Bayar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  props: ["pendaftar"],
  data() {
    return {
      bank: null,
      jumlah_pembayaran: null,
      no_registrasi: null,
    };
  },
  computed: {
    hasInvoices() {
      const tagihan = this.pendaftar?.tagihan;

      return tagihan?.length > 0;
    },
    hasBiaya() {
      return this.pendaftar.pembayaran.biaya_pendaftaran > 0;
    },
    notLunas() {
      return this.pendaftar.pembayaran?.status !== "lunas";
    },
  },
  methods: {
    bayar() {
      if (!this.bank) {
        return;
      }
      this.$store.commit("setLoading", true);

      this.$axios
        .post("/va/invoice", {
          bank: this.bank,
          no_registrasi: this.pendaftar.akun_pendaftar.no_registrasi,
          // jumlah_pembayaran: this.jumlah_pembayaran
        })
        .then((res) => {
          this.$emit("refreshBio", true);
          // this.$root.$emit('setNotif', {
          //   title: res.data.status,
          //   message: res.data.deskripsi,
          //   type: 'success'
          // });
        })
        .catch(() => { })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
    formatFromIso(dt) {
      return DateTime.fromISO(dt).toFormat("dd-LLL-yyyy");
    },
    toRelative(dt) {
      return DateTime.fromISO(dt).toRelative();
    },
  },
  filters: {
    formattedRupiah(val) {
      return new Intl.NumberFormat("id-ID", { useGrouping: true }).format(val);
    },
  },
};
</script>
